import * as React from "react";
import styled from "styled-components";
import {Link} from "gatsby";

import Layout from "../components/Layout";

const ErrorCode = styled.h1`
  ${props => props.theme.typography.h2};
  font-weight: 500;
  text-align: center;
`;

const Message = styled.h3`
  ${props => props.theme.typography.h5};
  text-align: center;

  padding-bottom: 180px;
`;

// markup
const NotFoundPage = () => {
  return (
    <Layout>
      <ErrorCode>404 Not Found</ErrorCode>
      <Message>
        Sorry, we couldn't find what you were looking for. Go{" "}
        <Link to="/">Home</Link>
      </Message>
    </Layout>
  );
};

export default NotFoundPage;
